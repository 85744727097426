import '@qb/polyfills/browser-polyfills';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps, AppContext } from 'next/app';
import { userAgentFromString } from 'next/server';
import { EmotionCacheProviderProps } from '@mui/material-nextjs/v14-pagesRouter';
import { ToastProvider } from '@qb/frontend/components/ToastProvider';
import { getWebApiBaseURL } from '@qb/frontend/utils/hosts/apiHost';
import { setSocketApiUrl } from '@qb/httpRequest/SocketRequest';
import { webQueries } from '@qb/httpRequest/webQueries';
import Constants from '@/shared/Constants';
import { AppDisplayDataResponse } from '@/shared/types/controllers/AppControllerTypes';
import { PartCategoryFooterCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { NEXTJS_HAS_AUTHENTICATED_LAYOUT_COOKIE } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { PagesRouterThemeProvider } from '@/src/components/Common/PagesRouterThemeProvider';
import ErrorBoundary from '@/src/components/Components/ErrorBoundary';
import { DeployRefreshManager } from '@/src/components/DeployRefreshManager/DeployRefreshManager';
import { AppLayout } from '@/src/components/Layouts/AppLayout/AppLayout';
import { AppTrackingAndAnalytics } from '@/src/components/Layouts/AppTrackingAndAnalytics';
import { ExternalScripts } from '@/src/components/Layouts/ExternalScripts';
import { DefaultSeoConfig } from '@/src/components/SEO/DefaultSEOConfig';
import { ModalContextProvider } from '@/src/contexts/CustomModalContext';
import { RightDrawerContextProvider } from '@/src/contexts/RightDrawerContext';
import { StoreProvider } from '@/src/store/StoreProvider';
import { queryClient } from '@/src/utils/queryClient';
setSocketApiUrl(getWebApiBaseURL());
type Props = AppProps & {
  hasAuthenticatedLayoutCookie: boolean;
  footerCategories: PartCategoryFooterCategoriesResponse['partCategories'];
  appDetails?: AppDisplayDataResponse['app'];
  isUserAgentDeviceMobile: boolean;
  emotionCache: EmotionCacheProviderProps['emotionCache'];
};
function App({
  Component,
  hasAuthenticatedLayoutCookie,
  footerCategories,
  appDetails,
  isUserAgentDeviceMobile,
  ...propsRest
}: Props) {
  const {
    pageProps,
    router
  } = propsRest;
  if ([`/cart/stripeRedirect`].includes(router.pathname)) {
    return <Component {...pageProps} {...propsRest} router={router} />;
  }
  return <>
      <DefaultSeoConfig data-sentry-element="DefaultSeoConfig" data-sentry-source-file="_app.tsx" />
      <StoreProvider data-sentry-element="StoreProvider" data-sentry-source-file="_app.tsx">
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <AppTrackingAndAnalytics salesPilotAppID={appDetails?.id} data-sentry-element="AppTrackingAndAnalytics" data-sentry-source-file="_app.tsx" />
          <PagesRouterThemeProvider isUserAgentDeviceMobile={isUserAgentDeviceMobile} emotionCache={propsRest.emotionCache} data-sentry-element="PagesRouterThemeProvider" data-sentry-source-file="_app.tsx">
            <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
              <RightDrawerContextProvider data-sentry-element="RightDrawerContextProvider" data-sentry-source-file="_app.tsx">
                <ModalContextProvider data-sentry-element="ModalContextProvider" data-sentry-source-file="_app.tsx">
                  <DeployRefreshManager data-sentry-element="DeployRefreshManager" data-sentry-source-file="_app.tsx" />
                  <AppLayout hasAuthenticatedLayoutCookie={hasAuthenticatedLayoutCookie} footerCategories={footerCategories} appDetails={appDetails} {...pageProps} data-sentry-element="AppLayout" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} {...propsRest} router={router} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                  </AppLayout>
                </ModalContextProvider>
              </RightDrawerContextProvider>
            </ErrorBoundary>
          </PagesRouterThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
        </QueryClientProvider>
      </StoreProvider>
      <ToastProvider data-sentry-element="ToastProvider" data-sentry-source-file="_app.tsx" />
      <ExternalScripts data-sentry-element="ExternalScripts" data-sentry-source-file="_app.tsx" />
    </>;
}

/**
 * Using getInitialProps in App will disable Automatic Static Optimization.
 * https://nextjs.org/docs/pages/building-your-application/routing/custom-app#getinitialprops-with-app
 */
App.getInitialProps = async ({
  ctx: {
    req
  }
}: AppContext) => {
  try {
    const hasAuthenticatedLayoutCookie = req?.headers.cookie?.includes(`${NEXTJS_HAS_AUTHENTICATED_LAYOUT_COOKIE}=`);
    const {
      device
    } = userAgentFromString(req?.headers['user-agent'] || '');
    const [footerCategoriesResponse, appDetails] = await Promise.all([webQueries.partCategory.footerCategories.queryFn(), webQueries.app.displayData(Constants.Widget.QB_APP_ID).queryFn()]);
    return {
      isUserAgentDeviceMobile: device.type === 'mobile',
      footerCategories: footerCategoriesResponse?.partCategories || [],
      hasAuthenticatedLayoutCookie,
      appDetails
    };
  } catch {
    return {
      footerCategories: [],
      hasAuthenticatedLayoutCookie: false,
      isUserAgentDeviceMobile: false
    };
  }
};
export default App;